import React from 'react'
import './Genesis.scss'
import luv from '../../assets/luv.png'
import pipl from '../../assets/slider.gif'
const Genesis = () => {
  return (
    <div className='genesis-container' id='genesis'>
      <div className='genesis'>
        <div className='genesis-word primary-font'>
          <h1>WHAT IS WTF.PIPL?</h1>
          <p>
            Wtf.pipl? is our first collection, the Original Pipl. With this
            collection our aim is to imagine the Pipl as a diverse group of
            people with over 300 traits, all different, but accepting these
            differences unconditionally. Every wtf.pipl? will be different, but
            on close inspection you will realize that they are still inherently
            the same, working towards the same goal in promoting the wellbeing
            of our planet and of all Pipl. Every wtf.pipl? holder, as an
            original, will be prioritized accordingly with every new collection
            by the Pipl.
          </p>
        </div>
        <img src={luv} alt='luv' />
      </div>
      <img src={pipl} alt='pipl' className='carousell' />
    </div>
  )
}

export default Genesis
