import React from "react";
import "./Organization.scss";
import Amazon from "../../assets/Amazon.png";
import Earth from "../../assets/grey_earth.png";
import ecologi from "../../assets/ecologi.svg";
import doctor from "../../assets/doctor.png";
import "animate.css";
import gd from "../../assets/GD.png";

const Organization = () => {
  const ORGANIZATIONS = [
    {
      img: Amazon,
      color: "#2D775C",
      text: "Protecting the Amazon rainforest in solidarity with Indigenous peoples.",
      text_color: "#fff",
      link: "https://amazonwatch.org/",
    },
    {
      img: ecologi,
      color: "#55C5E2",
      text: "Facilitating the funding of carbon offset projects and tree planting around the world.",
      text_color: "#fff",
      link: "https://ecologi.com/piplworld",
    },
    {
      img: gd,
      color: "#0B5846",
      text: `Sending money directly to the world's poorest households`,
      text_color: "#fff",
      link: "https://www.givedirectly.org/ ",
    },
    {
      img: doctor,
      color: "#FF0000",
      text: "Providing independent, impartial medical humanitarian assistance to the people who need it most.",
      text_color: "#fff",
      link: "https://www.msf.org/",
    },
  ];

  return (
    <div className="organization_container">
      <h1 className="title primary-font">Organizations We Work With</h1>
      <div className="organizations">
        {ORGANIZATIONS.map((item, index) => (
          <div className="organization" key={index}>
            <img
              className="animate__animated animate__shakeY animate__infinite"
              src={item.img}
              alt={index}
            />
            <p className="primary-font">{item.text}</p>
            <a href={item.link} target="_blank">
              {item.color ? (
                <button
                  className="primary-font"
                  style={{
                    backgroundColor: item.color,
                    color: item.text_color,
                    "--hover-color": item.color,
                    "--hover-text": item.text_color,
                  }}
                >
                  Browse
                </button>
              ) : (
                <></>
              )}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Organization;
