import React, { useState, useEffect } from "react";
import "./Land.scss";
import logo from '../../assets/logo3.png';
import bigland from "../../assets/bigland.gif";
import youdont from "../../assets/youdont1.svg";
import youdont1 from "../../assets/youdont2.png";
import blkland from "../../assets/blkLand.gif";
import piplworld from "../../assets/logo4.png";
import piplworld2 from "../../assets/logo5.png";
import opensea from '../../assets/opensealandbutton.png'
import weare from "../../assets/weare.png";
import land from "../../assets/Landnobg.gif";
import { motion } from "framer-motion/dist/framer-motion";
import ReactFreezeframe from "react-freezeframe";
import sand1 from "../../assets/sandbox1.png";
import sand2 from "../../assets/sandbox2.png";
import table from "../../assets/table.png";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import toast, { Toaster } from "react-hot-toast";
import lv1 from "../../assets/1.gif";
import lv2 from "../../assets/2.gif";
import lv3 from "../../assets/3.gif";
import lv4 from "../../assets/4.gif";
import lv5 from "../../assets/5.gif";

//const tokenID = [1024, 3058, 2111, 1789, 2458, 6542, 4785, 3333, 7895, 8000];
const piplUrl =
  "https://pipl.mypinata.cloud/ipfs/QmVPUiF3J2aPqTbwQzNgkSkfdLP1sYZPrPVQLriQhZ3vPx/";

const weareUrl =
  "https://pipl.mypinata.cloud/ipfs/QmbXKkm691P1Xdm7JopNYDx1ZuJF9aSHMxuVoXrjEa51zn/";

const weareOwned = [258, 1049, 2567, 1428, 1773];

const points = [
  {
    title: "Things to know about LAND:",
    point1: "LAND supply 3K, Claim from July 22nd EST 10am.",
    point2:
      'Which tier of LAND you get from burning "wtf.pipl?" will depend on the combine rarity score of all 4 "wtf.pipl?", higher rarity will receive higher level LAND.',
    point3:
      "Each Land represents an ownership of this world as we operate an income sharing model. Higher level of LAND would get you more of entire WeArePiplWorld income share.",
    point4: "Each “WeAre by John Johnny” can only be used once for land claim",
    point5:
      "Holder of LAND will having access to the Pipl Fund which will be created to support our Land holders with their own personal fundraising campaigns.",
    point6: "Free Pass to WeArePiplWorld city hall and events in SANDBOX",
    point7: "Airdrop $pipl token. Snapshot date TBA.",
    point8: "Stake your land to generate token everyday.",

    pic1: sand1,
    pic2: sand2,
  },
  {
    title: "Use of $pipl token",
    title2: "WeArePiplWorld in the SANDBOX",
    point1: "Play games and win more token in the WeArePiplWorld.",
    point2: "Buy merch.",
    point3: "Donate or vote for the charity project you want to support.",
  },
  {
    title: "What about “WeAre by John Johnny”",
    point1:
      "Every “WeAre by John Johnny” will have reward boost when you play games, and if you lose in game you can have % cash back.",
    point2:
      "More “WeAre by John Johnny ” you hold, more reward boost and cash back will add up.",
    point3: "Airdrop $pipl token. Snapshot date TBA.",
    point4: "Free Pass to WeArePiplWorld city hall and events in SANDBOX",
  },
];

const lands = [
  {
    name: "WeAreCalm",
    lv: "Level 1",
    image: lv1,
    col: "#2ef3e3",
  },
  {
    name: "WeAreRelax",
    lv: "Level 2",
    image: lv2,
    col: "#2781f7",
  },
  {
    name: "WeAreHappy",
    lv: "Level 3",
    image: lv3,
    col: "#9e4de0",
  },
  {
    name: "WeAreRich",
    lv: "Level 4",
    image: lv4,
    col: "#f1250a",
  },
  {
    name: "WeAreFree",
    lv: "Level 5",
    image: lv5,
    col: "#f8fc1a",
  },
];

const data = [
  {
    name: "Chance to get Level 1",
    c1: "60%",
    c2: "0%",
    c3: "0%",
    c4: "0%",
    c5: "0%",
  },
  {
    name: "Chance to get Level 2",
    c1: "20%",
    c2: "65%",
    c3: "0%",
    c4: "0%",
    c5: "0%",
  },
  {
    name: "Chance to get Level 3",
    c1: "10%",
    c2: "20%",
    c3: "70%",
    c4: "0%",
    c5: "0%",
  },
  {
    name: "Chance to get Level 4",
    c1: "7%",
    c2: "10%",
    c3: "20%",
    c4: "85%",
    c5: "0%",
  },
  {
    name: "Chance to get Level 5",
    c1: "3%",
    c2: "5%",
    c3: "10%",
    c4: "15%",
    c5: "100%",
  },
];

const columns = [
  {
    header: 'Combine rarity score of all 4 "wtf.pipl"',
    accessorKey: "name",
  },
  {
    header: "< 663.06",
    accessorKey: "c1",
  },
  {
    header: "663.06~816.32",
    accessorKey: "c2",
  },
  {
    header: "816.33~1031.48",
    accessorKey: "c3",
  },
  {
    header: "> 1031.48",
    accessorKey: "c4",
  },
  {
    header: "1 out of 4 is Legendary",
    accessorKey: "c5",
  },
];

const Land = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState("pipl");
  const [weareSelected, setWeareSelected] = useState([]);
  const [numberSelected, setNumberSelected] = useState(0);
  const [weareNumberSelected, setWeareNumberSelected] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [check, setCheck] = useState("");
  const [checkResult, setCheckResult] = useState("Result:");

  //const [weare, setWeare] = useState(2);
  useEffect(() => {
    console.log(checkResult);
  }, [checkResult]);
  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "true") {
        try {
          dispatch(connect());
        } catch (ex) {
          console.log(ex);
        }
      }
    };
    connectWalletOnPageLoad();
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      getConfig();
      getData();
    }
  }, []);

  useEffect(() => {
    getConfig();
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    console.log(selected, numberSelected);
    if (
      blockchain.account !== "" &&
      blockchain.smartContract !== null &&
      blockchain.loading == false
    ) {
      console.log(data.ownedPipl);
      console.log(data.approvalForAll);
      data.approvalList.map((item) => console.log(item[1]));
    }
  }, [selected]);

  useEffect(() => {
    console.log(weareSelected);
  }, [weareSelected]);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const CheckClaimed = async (tokenId) => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const tx = await blockchain.smartContract.methods
        .claimedWeareId(tokenId)
        .call()
        .then(setCheckResult);
      console.log(checkResult);
      return tx;
    }
  };

  const Approve = (tokinId) => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.APPROVE_GAS_LIMIT;

    const tx = blockchain.pipl_smartContract.methods
      .approve(CONFIG.CONTRACT_ADDRESS, tokinId)
      .send({
        gasLimit: gasLimit,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: cost,
      })
      .once("error", (err) => {
        console.log(err);
      })
      .then((receipt) => {
        //console.log(receipt);

        dispatch(fetchData(blockchain.account));
      });
    return tx;
  };

  const ApproveForAll = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.APPROVE_GAS_LIMIT;

    const tx = blockchain.pipl_smartContract.methods
      .setApprovalForAll(CONFIG.CONTRACT_ADDRESS, true)
      .send({
        gasLimit: gasLimit,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: cost,
      })
      .once("error", (err) => {
        console.log(err);
      })
      .then((receipt) => {
        //console.log(receipt);

        dispatch(fetchData(blockchain.account));
      });
    return tx;
  };

  const Claim = async (tokenIds, weareSelected) => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;

    const data = {
      tokens: tokenIds,
    };

    console.log(JSON.stringify(data));

    const response = await fetch(
      "https://pipl-api.netlify.app/.netlify/functions/api",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    response.json().then((data) => {
      //console.log(data.tokens);

      toast.promise(
        blockchain.smartContract.methods
          .claim(
            data.tokens,
            weareSelected,
            data.totalScore,
            data.v,
            data.r,
            data.s
          )
          .send({
            gasLimit: gasLimit,
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: cost,
          })
          .once("error", (err) => {
            console.log(err);
          })
          .then((receipt) => {
            //console.log(receipt);
            setSelected([]);
            setNumberSelected(0);
            setTotalScore(0);
            setWeareSelected([]);
            setWeareNumberSelected(0);

            dispatch(fetchData(blockchain.account));
          }),
        {
          loading: "Claiming...",
          success: <b>Sucessfully Claimed!</b>,
          error: <b>Something went wrong</b>,
        }
      );
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="Background"
    >
      <div className="big_sec">
        <div className="intro">
          <div className="sub">
            <img src={land} />
            <div className="word">
              <div className="wearelandcontainer">
              <img src={piplworld2} alt="logo" className="wearepipllogo"/>

               <a href='https://opensea.io/collection/wearepiplworld-land-v2' >
                <img src={opensea} alt='opensea' className="landopensea" />
              </a>
              </div>

              {/* <h1>WeArePiplWorld</h1> */}
              <p>
                WeArePiplWorld is an ecosystem open to everyone-designed to
                bridge philanthropy and web3.0. With charity casino, little
                games, merch shops, City Hall inside - we aim to revolutionize
                web3.0 charity projects! Each Land represents ownershipan of
                this Id as we operate an income sharing model.
              </p>

              <div className="rules">
                <div className="rule">
                  <img src={weare} alt="weare" className="wearelogo" />
                  <p className="rule_1">Hold 2 x WeAre NFT</p>
                </div>
                <h1>+</h1>
                <div className="rule">
                  <img src={piplworld} alt="piplworld" className="pipllogo" />
                  <p className="rule_2">Burn 4 x wtf.pipl NFT</p>
                </div>
                <h1>=</h1>
                <div className="rule">
                  <img src={blkland} alt="blkland" className="blkland" />
                  <p>1 x WeArePiplWorld</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="howToClaim">
          <h2>How to claim WeArePiplWorld LAND</h2>
          <p>Hold 2 "WeAre by John Johnny" + burn 4 "wtf.pipl?" = 1 LAND</p>
          <p>Hold 4 "WeAre by John Johnny" + burn 8 "wtf.pipl?" = 2 LANDs</p>
          <p>and so on.....</p>
        </div>
        {points.map((item, index) => (
          <div className="points">
            <div className="bigtitle_container">
              {item.title2 && <h1 className="bigtitle">{item.title2}</h1>}
            </div>

            <h2>{item.title}</h2>
            <ol>
              <li>{item.point1}</li>
              <li>{item.point2}</li>
              {item.point3 && <li>{item.point3}</li>}
              {item.point4 && <li>{item.point4}</li>}
              {item.point5 && <li>{item.point5}</li>}
              {item.point6 && <li>{item.point6}</li>}
              {item.point7 && <li>{item.point7}</li>}
              {item.point8 && <li>{item.point8}</li>}
            </ol>
            <div className="pointPic">
              {item.pic1 && <img src={item.pic1} alt="sand1" />}
              {item.pic2 && <img src={item.pic2} alt="sand2" />}
            </div>
          </div>
        ))}

        <h1 className="level_title">Level of LAND</h1>
        <div className="landPic">
          {lands.map((item, index) => (
            <div className="indiLand" key={index}>
              <p>{item.lv}</p>
              <p style={{ color: item.col }}>{item.name}</p>
              <img src={item.image} alt={item.lv} />
            </div>
          ))}
        </div>
        <img src={table} alt="table" className="table" />
      </div>
      {data.loading == true ? (
        <div className="loading_section">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : blockchain.account !== "" &&
        blockchain.smartContract !== null &&
        data.loading == false ? (
        <div className="weland_mint_section">
          <Toaster />

          <div className="page_selector">
            <h2
              className={currentPage == "pipl" ? "activepage" : "inactivepage"}
              onClick={() => {
                setCurrentPage("pipl");
              }}
            >
              PIPL
            </h2>
            <h2
              className={currentPage == "weare" ? "activepage" : "inactivepage"}
              onClick={() => {
                setCurrentPage("weare");
              }}
            >
              WeAre
            </h2>
          </div>
          {currentPage === "pipl" && (
            <div className="pipl_pictures">
              {/*console.log(data.approvalList)}
            {console.log("loading ", blockchain.loading)}
        {console.log("approvalForAll :", data.approvalForAll)*/}
              {data.approvalList.length == 0 ? (
                <img src={youdont} className="youdont" />
              ) : (
                data.approvalList.map((item) => (
                  <div className="picture" key={item[0]}>
                    <h3 className="id">{"ID : " + item[0]}</h3>
                    <h3 className="id2">{"score : " + item[2]}</h3>
                    <img
                      src={piplUrl + item[0] + ".png"}
                      //onload={<div>123</div>}
                      //className={selected.includes(item[0]) ? 'selected' : ''}
                    />
                    {/*console.log(item)*/}
                    {selected.includes(item[0]) ? (
                      <button
                        className="unselect"
                        //key={item}
                        onClick={() =>
                          setSelected(
                            (selected) =>
                              selected.filter((ite) => ite !== item[0]),
                            setNumberSelected(numberSelected - 1),
                            setTotalScore(totalScore - parseFloat(item[2]))
                          )
                        }
                      >
                        UNSELECT
                      </button>
                    ) : (
                      <button
                        className={
                          //numberSelected <= 4 ||
                          numberSelected < 4 &&
                          (item[1] == true || data.approvalForAll == true)
                            ? ""
                            : "dis"
                        }
                        disabled={
                          numberSelected < 4 &&
                          (item[1] == true || data.approvalForAll == true)
                            ? false
                            : true
                        }
                        //key={item}
                        onClick={() =>
                          setSelected(
                            (selected) => [...selected, item[0]],
                            setNumberSelected(numberSelected + 1),
                            setTotalScore(totalScore + parseFloat(item[2]))
                          )
                        }
                      >
                        SELECT
                      </button>
                    )}

                    <button
                      disabled={data.approvalForAll == true || item[1] == true}
                      className={
                        data.approvalForAll == false && item[1] == false
                          ? "approve"
                          : "approve disa"
                      }
                      onClick={() =>
                        toast.promise(Approve(item[0]), {
                          loading: "Approving...",
                          success: <b>Sucessfully Approved!</b>,
                          error: <b>Something went wrong</b>,
                        })
                      }
                    >
                      APPROVE
                    </button>
                  </div>
                ))
              )}
            </div>
          )}
          {currentPage === "weare" && (
            <div className="pipl_pictures border">
              {/*console.log(data.approvalList)}
            {console.log("loading ", blockchain.loading)}
        {console.log("approvalForAll :", data.approvalForAll)*/}
              {data.weAreList.length == 0 ? (
                <img src={youdont1} className="youdont" />
              ) : (
                data.weAreList.map((item) => (
                  <div className="picture" key={item[0]}>
                    <h3 className="id">{"ID : " + item[0]}</h3>
                    {/*<p>{item[1].toString()}</p>*/}
                    <ReactFreezeframe className="freeze">
                      <img
                        src={weareUrl + item[0] + ".gif"}
                        //onload={<div>123</div>}
                        className="freeze"
                      />
                    </ReactFreezeframe>
                    {/*console.log(item)*/}
                    {weareSelected.includes(item[0]) ? (
                      <button
                        className="unselect"
                        //key={item}
                        onClick={() =>
                          setWeareSelected(
                            (weareSelected) =>
                              weareSelected.filter((ite) => ite !== item[0]),
                            setWeareNumberSelected(weareNumberSelected - 1)
                          )
                        }
                      >
                        UNSELECT
                      </button>
                    ) : (
                      <button
                        className={
                          //numberSelected <= 4 ||
                          weareNumberSelected < 2 && item[1] == false
                            ? ""
                            : "dis"
                        }
                        disabled={
                          weareNumberSelected < 2 && item[1] == false
                            ? false
                            : true
                        }
                        //key={item}
                        onClick={() =>
                          setWeareSelected(
                            (weareSelected) => [...weareSelected, item[0]],
                            setWeareNumberSelected(weareNumberSelected + 1)
                          )
                        }
                      >
                        {item[1] == true ? "CLAIMED" : "SELECT"}
                      </button>
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="weland_mint_section connectWallet">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            Connect Wallet
          </button>
        </div>
      )}

      {blockchain.account !== "" && blockchain.smartContract !== null && (
        <div className="mint_sec">
          <div className="check_section">
            <h3>Check Whether Certain WeAre was Claimed</h3>
            <div className="check_sec">
              <input
                type="text"
                onChange={(e) => {
                  setCheck(e.target.value);
                }}
                placeholder="Input a Token ID e.g 1285"
              />
              <button
                onClick={() => {
                  CheckClaimed(check);
                  console.log(checkResult);
                }}
              >
                Submit
              </button>
            </div>
            {checkResult == "Result:" ? (
              <h3>Result:</h3>
            ) : checkResult == true ? (
              <h3>
                Result: <span style={{ color: "#ee4b60" }}>Claimed</span>
              </h3>
            ) : (
              <h3>
                Result: <span style={{ color: "#3bef58" }}>Not Claimed</span>
              </h3>
            )}
          </div>
          <h3 className="totalScore">
            {"Total Scores : " + totalScore.toFixed(2)}
          </h3>
          <div className="mint_buttons">
            <h3 className={weareNumberSelected < 2 ? "notenough" : ""}>
              WeAre Selected: {weareNumberSelected}
            </h3>

            <button
              disabled={
                data.approvalForAll == true || data.approvalList.length == 0
              }
              className={
                data.approvalForAll == false && data.approvalList.length !== 0
                  ? "approves"
                  : "approves disa"
              }
              onClick={() =>
                toast.promise(ApproveForAll(), {
                  loading: "Approving...",
                  success: <b>Sucessfully Approved!</b>,
                  error: <b>Something went wrong</b>,
                })
              }
            >
              APPROVE FOR ALL
            </button>
            <button
              disabled={
                weareNumberSelected >= 2 &&
                numberSelected == 4 &&
                numberSelected != 0
                  ? false
                  : true
              }
              className={
                weareNumberSelected >= 2 &&
                numberSelected == 4 &&
                numberSelected != 0
                  ? "get"
                  : "disab"
              }
              onClick={() => {
                Claim(selected, weareSelected);
              }}
            >
              GET Land
            </button>
            <h3
              className={
                numberSelected != 4 || numberSelected == 0 ? "notenough" : ""
              }
            >
              PIPL selected : {numberSelected}
            </h3>
          </div>
        </div>
      )}
      <img src={bigland} className="bigland" />
    </motion.div>
  );
};

export default Land;
