import React from 'react'
import './App.scss'
import {
  Navbar,
  Background,
  Benifits,
  Genesis,
  Distributions,
  Organization,
  Hero,
  Story,
  Media,
  Roadmap,
  Team,
  FooterHero,
  Footer,
  Minting,
  Land,
} from './components'

import {
  Routes, // instead of "Switch"
  Route,
  Link,
  useLocation,
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'

const App = () => {
  const location = useLocation()
  return (
    <div>
      <Navbar />
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route
            exact
            path='/'
            element={
              <motion.div
                className='BG'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className='App'>
                  <Hero />
                  <Minting />
                  <Background />
                  <Benifits />
                  <Story />
                  <Genesis />
                  {/* <Distributions /> */}
                  <Organization />
                  <Media />

                  <Team />
                </div>
                <FooterHero />
              </motion.div>
            }
          />
          <Route exact path='/land' element={<Land />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  )
}

export default App
