import React from 'react'
import './Roadmap.scss'

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import Left from '../../assets/roadmap/roadmap_left.png'
import Right from '../../assets/roadmap/roadmap_right.png'

function Roadmap() {
  const roadmap = [
    {
      title: 'Phase One',
      text: 'Pipl Genesis will be launch on May 31st, 2022 Mint Price TBA.',
    },
    {
      title: 'Phase Two',
      text: 'After minted, Pipl will donate part of its income to relevant non-profit and charitable organizations. In Q3 of 2022, all Pipl Genesis holders will receive a free airdrop of merchandise. Trust us, you will be pleasantly surprised when the airdrop merchandise is announced.',
    },
    {
      title: 'Phase Three',
      text: 'Pipl’s second collection will be released in Q3 of 2022. Every OG will automatically be on the whitelist, and   every holder of Pipl Genesis will be prioritized. Details will be announced after the Pipl Genesis’ reveal.',
    },
    {
      title: 'Phase Four ',
      text: 'We will select a list of global cities through the Pipl community and jointly organize a Pipl gathering with local charities in the selected cities. Holding Pipl is not just holding a PFP. We will also have some amazing utilities such as our own Tokenomic, and community voting rights too! More details to come in Roadmap 2.0!',
    },
  ]
  return (
    <div className='roadmap' id='roadmap'>
      <h1 className='header'>ROADMAP</h1>
      <p className='text'>
        Our roadmap begins with our community. We believe that the strongest
        long-term community in this space is for those who believe in Love and
        Peace. Our first goal is to create a meeting place for such a community,
        but we also have a great vision and a lot of creative ideas in store for
        the future.
      </p>
      <div className='container'>
        <img src={Left} alt='Left' />
        <div className='timeline'>
          <VerticalTimeline lineColor='#43436B' layout='1-column-left'>
            {roadmap.map((item, index) => (
              <div className='items' key={index}>
                <VerticalTimelineElement
                  className='vertical-timeline-element--work'
                  contentStyle={{
                    background: 'unset',
                    boxShadow: 'unset',
                    color: '#fff',
                  }}
                  contentArrowStyle={{ all: 'unset' }}
                  iconStyle={{
                    background: '#fff',
                    boxShadow: '0 0 0 10px #43436B',
                    width: '25px',
                    height: '25px',
                    top: '30px',
                    left: '10px',
                  }}
                >
                  <h1 className='vertical-timeline-element-title'>
                    {item.title}
                  </h1>
                  <p>{item.text}</p>
                  <p className='text_2'>{item.text2}</p>
                </VerticalTimelineElement>
              </div>
            ))}
          </VerticalTimeline>
        </div>
        <img src={Right} alt='Right' />
      </div>
    </div>
  )
}

export default Roadmap
