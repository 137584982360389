import React from 'react'
import './Hero.scss'
import Hero_image from '../../assets/piplhero.gif'

const Hero = () => {
  return (
    <div className='Hero_container' id='home'>
      <img src={Hero_image} alt='hero1'></img>
    </div>
  )
}

export default Hero
