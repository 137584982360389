import React, { useState, useEffect } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './Navbar.scss'
import logo from '../../assets/logo3.png'
import { useSpring, animated, easings } from 'react-spring'
import twitter from '../../assets/twitterbutton.svg'
import discord from '../../assets/discordbutton.svg'
import opensea from '../../assets/openseabutton.svg'
import instagram from '../../assets/Instagram.svg'
import { BiWallet } from 'react-icons/bi'
import { HashLink } from 'react-router-hash-link'
import weare from '../../assets/weare_logo.png'

import { useDispatch, useSelector } from 'react-redux'
import { connect } from '../../redux/blockchain/blockchainActions'

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(false)
  const fade = useSpring({
    opacity: toggleMenu ? 1 : 0,
    marginTop: toggleMenu ? 0 : -700,
    zIndex: toggleMenu ? 9999 : 1,
    config: { duration: 700 },
    easing: easings.easeInOutBack,
  })
  const handleScroll = () => {
    const position = window.pageYOffset
    if (position > 10) {
      setScrollPosition(true)
    } else {
      setScrollPosition(false)
    }
  }

  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          dispatch(connect())
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    connectWalletOnPageLoad()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const small = useSpring({
    height: scrollPosition ? 80 : 120,
  })

  const small2 = useSpring({
    height: scrollPosition ? 110 : 160,
  })

  const mask = useSpring({
    display: toggleMenu ? 'block' : 'none',
    opacity: toggleMenu ? 1 : 0,
    config: { duration: 700 },
  })
  return (
    <div className='Big' sticky='top'>
      <div className='BV__navbar'>
        <div className='BV__navbar-links'>
          <HashLink to='/#home'>
            <animated.div href='/' className='BV__navbar-links_logo'>
              <animated.img href='/' src={logo} alt='logo' />
            </animated.div>
          </HashLink>

          <div className='BV__navbar-links_container'>
            <p>
              <HashLink to='/#genesis'>wtf.pipl?</HashLink>
            </p>
            <p>
              <HashLink to='/#media'>Media & Press</HashLink>
            </p>

            <p>
              <HashLink to='/#team'>Team</HashLink>
            </p>
            <p>
              <HashLink to='/land/#top' className='land'>
                Land
              </HashLink>
            </p>
            <p>
              <a href='https://we-are.cool/' target='_blank'>
                <img src={weare} alt='weare' />
              </a>
            </p>
            <div className='social_container'>
              <a href='https://twitter.com/PiplWorld' target='_blank'>
                <img src={twitter} alt='twitter' />
              </a>
                 <a href='https://opensea.io/collection/wtfpipl' target='_blank'>
                <img src={opensea} alt='opensea' />
              </a>
              {/* <a href='http://www.discord.gg/pipl' target='_blank'>
                <img src={discord} alt='discord' />
              </a> */}
              <a
                href='https://www.instagram.com/piplworld2022/'
                target='_blank'
              >
                <img src={instagram} alt='instagram' />
              </a>
            </div>
          </div>
        </div>
        <div className='BV__navbar-connect'>
          {blockchain.account === '' || blockchain.account == null ? (
            <button
              //type="button"
              onClick={(e) => {
                e.preventDefault()
                dispatch(connect())
              }}
            >
              <BiWallet size={27} className='wallet-icon' />
              <p>Connect Wallet</p>
            </button>
          ) : (
            <button type='button'>
              <BiWallet size={27} className='wallet-icon' />
              <p>
                {blockchain.account.substring(0, 4) +
                  '...' +
                  blockchain.account.substring(
                    blockchain.account.length - 3,
                    blockchain.account.length
                  )}
              </p>
            </button>
          )}
        </div>
        <div className='BV__navbar-menu'>
          {toggleMenu ? (
            <RiCloseLine
              color='#fff'
              size={27}
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          ) : (
            <RiMenu3Line
              color='#fff'
              size={27}
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          )}
        </div>
      </div>
      <div className='mask_div'>
        <animated.div
          style={mask}
          className='mask'
          onClick={() => setToggleMenu(!toggleMenu)}
        ></animated.div>
      </div>
      <div className='BV__navbar-menu_BIG'>
        <animated.div style={fade} className='BV__navbar-menu_container'>
          <div className='social_container social-menu'>
            <a href='https://twitter.com/PiplWorld' target='_blank'>
              <img src={twitter} alt='twitter' />
            </a>
            <a href='http://www.discord.gg/pipl' target='_blank'>
              <img src={discord} alt='discord' />
            </a>
            <a href='https://www.instagram.com/piplworld2022/' target='_blank'>
              <img src={instagram} alt='discord' />
            </a>
          </div>
          <div>
            <HashLink onClick={() => setToggleMenu(!toggleMenu)} to='/#genesis'>
              Pipl Genesis?
            </HashLink>
          </div>
          <div>
            <HashLink onClick={() => setToggleMenu(!toggleMenu)} to='/#media'>
              Media & Press
            </HashLink>
          </div>

          <div>
            <HashLink onClick={() => setToggleMenu(!toggleMenu)} to='/#team'>
              Team
            </HashLink>
          </div>
          <div>
            <HashLink
              onClick={() => setToggleMenu(!toggleMenu)}
              to='/land/#top'
              className='land'
            >
              Land
            </HashLink>
          </div>
        </animated.div>
      </div>
    </div>
  )
}

export default Navbar
