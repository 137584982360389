import React from 'react'
import './Distributions.scss'
import disleft from '../../assets/disleft.png'
import disright from '../../assets/disright.png'

function Distributions() {
  return (
    <div className='distributions'>
      <h1>Funding Distributions</h1>
      <div className='funding_container'>
        <img src={disleft} alt='disleft' />
        <div className='funding'>
          <h2>Daily Operation:</h2>
          <p>40% will be used for the daily operations of Pipl.</p>
          <h2>Marketing:</h2>
          <p>10% will be used for community marketing and promotion.</p>
        </div>
        <div className='funding'>
          <h2>NGOs:</h2>
          <p>
            30% of our proceeds will go directly to the NGOs we work with,
            including donations and event sponsoring.
          </p>
          <h2>Fund:</h2>
          <p>
            20% will be used to establish a fund to select the individual
            projects or events we support.
          </p>
        </div>
        <img src={disright} alt='disright' />
      </div>
    </div>
  )
}

export default Distributions
