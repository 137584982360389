import React from 'react'
import './Media.scss'
import Media from '../../assets/media/media.png'

function media() {
  return (
    <div className='media' id='media'>
      <h1>Media & Press About Us</h1>
      <img src={Media} alt='media' />
    </div>
  )
}

export default media
