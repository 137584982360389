import React from 'react'
import './Footer.scss'

import logo from '../../assets/logo3.png'
import twitter from '../../assets/footer/footer_twitter.png'
import discord from '../../assets/footer/footer_discord.png'
import instagram from '../../assets/footer/footer_instagram.png' 
import opensea from '../../assets/footer/footer_opensea.png' 

function Footer() {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='logo'>
          <a href='#home'>
            <img className='big' src={logo} alt='logo' />
          </a>
          <a href='https://twitter.com/PiplWorld' target='_blank'>
            <img src={twitter} alt='twitter' />
          </a>
             <a href='https://opensea.io/collection/wtfpipl' target='_blank'>
            <img src={opensea} alt='opensea' />
          </a>
          {/* <a href='http://www.discord.gg/pipl' target='_blank'>
            <img src={discord} alt='discord' />
          </a> */}
          <a href='https://www.instagram.com/piplworld2022/' target='_blank'>
            <img src={instagram} alt='discord' />
          </a>
        </div>
        <div className='footer_info'>
          <p>
            Partner with{' '}
            <span className='spec'>
              <a href='https://app.traitsniper.com/wtfpipl?' target='_blank'>
                trait sniper
              </a>
            </span>
          </p>
          <p>All rights reserved to Pipl. 2022</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
