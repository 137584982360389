// log
import store from "../store";
import * as fs from "fs";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    let score = require("./score.json");

    const CONFIG = await configResponse.json();
    try {
      /*let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply(1)
        .call();*/
      let account = await store.getState().blockchain.account;
      //console.log(account);
      let paused = await store
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();
      let ownedPipl = await store
        .getState()
        .blockchain.pipl_smartContract.methods.walletOfOwner(account)
        .call();
      let ownedWeare = await store
        .getState()
        .blockchain.weare_smartContract.methods.walletOfOwner(account)
        .call();
      let balanceOfPipl = await store
        .getState()
        .blockchain.pipl_smartContract.methods.balanceOf(account)
        .call();
      let balanceOfWeare = await store
        .getState()
        .blockchain.weare_smartContract.methods.balanceOf(account)
        .call();
      let approvalForAll = await store
        .getState()
        .blockchain.pipl_smartContract.methods.isApprovedForAll(
          account,
          CONFIG.CONTRACT_ADDRESS
        )
        .call();

      let approvalList = [];

      for (let item of ownedPipl) {
        let address = await store
          .getState()
          .blockchain.pipl_smartContract.methods.getApproved(item)
          .call();

        let approve = false;
        if (address == CONFIG.CONTRACT_ADDRESS) {
          approve = true;
        }

        let piplScore = score[item][1];

        approvalList.push([item, approve, (piplScore / 100.0).toFixed(2)]);
      }

      let weAreList = [];

      for (let item of ownedWeare) {
        let claimed = await store
          .getState()
          .blockchain.smartContract.methods.claimedWeareId(item)
          .call();

        weAreList.push([item, claimed]);
      }

      //console.log(weAreList);
      //console.log(approvalList);
      //console.log(ownedPipl);

      dispatch(
        fetchDataSuccess({
          //totalSupply,
          paused,
          ownedPipl,
          balanceOfPipl,
          balanceOfWeare,
          approvalForAll,
          approvalList,
          weAreList,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
