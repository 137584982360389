import React from 'react'
import './Benifits.scss'
import bcoin from '../../assets/bcoin.png'

const Benifits = () => {
  return (
    <div className='Benifits primary-font'>
      <h1>What’s the benefit of being a Pipl holder?</h1>
      <p>
        Not only is every NFT by the Pipl a donation to a reputable charity, but
        also a membership pass to our philanthropic community and an opportunity
        to participate in our philanthropic process. Every member will be a huge
        part of our community in terms of ownership and decision making.
        Together we will create our own tokenomic and decision making process in
        selecting representatives from the Pipl community to assist the Pipl in
        discovering and identifying new charities from around the world who
        needs our support, to personally participate in projects supported by
        the Pipl and to monitor these projects to ensure that our vision is
        being fulfilled. We believe that philanthropy is not just an individual,
        but collective process achieved through dedication, compassion and
        working together as a team.
      </p>

      <img src={bcoin} alt='bcoin' />
    </div>
  )
}

export default Benifits
