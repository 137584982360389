const initialState = {
  loading: false,
  totalSupply: 0,
  paused: true,
  ownedPipl: [],
  balanceOfPipl: 0,
  balanceOfWeare: 0,
  approvalForAll: false,
  approvalList: [],
  weAreList: [],
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        paused: action.payload.paused,
        ownedPipl: action.payload.ownedPipl,
        balanceOfPipl: action.payload.balanceOfPipl,
        balanceOfWeare: action.payload.balanceOfWeare,
        approvalForAll: action.payload.approvalForAll,
        approvalList: action.payload.approvalList,
        weAreList: action.payload.weAreList,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
