import React from 'react'
import './FooterHero.scss'

import hero from '../../assets/footer/bottom.jpg'

function FooterHero() {
  return (
    <div className='hero'>
      <img src={hero} alt='hero' />
    </div>
  )
}

export default FooterHero
