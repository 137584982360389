import React from 'react'
import './Team.scss'

import twitter from '../../assets/Twitter.png'

import TheXPsniper from '../../assets/team/TheXPsniper.png'
import OneMoreCoffee from '../../assets/team/OneMoreCoffee.png'
import Jacklin from '../../assets/team/Jacklin.png'
import Tony from '../../assets/team/Tony.png'
import ArtKoch from '../../assets/team/ArtKoch.png'
import Demon from '../../assets/team/Demon.png'
import web3media from '../../assets/team/web3media.png'

function Team() {
  const member = [
    {
      image: TheXPsniper,
      title: 'Co-Founder',
      name: 'TheXPsniper',
      text: 'A marketing expert with experience in the arts and culture and intercultural  communication.',
      media: twitter,
      link: 'https://twitter.com/TheXPsniper ',
    },
    {
      image: OneMoreCoffee,
      title: 'Co-Founder',
      name: 'OneMoreCoffee',
      text: 'A philanthropist with a passion for coffee, the arts and bringing people together.',
      media: twitter,
      link: 'https://twitter.com/twomorecoffee',
    },
    {
      image: Jacklin,
      title: 'Community Manager',
      name: 'Jacklin',
      text: 'A professional artist agent with more than 20 years of experience.',
    },
    {
      image: Tony,
      title: 'Head of Communication',
      name: 'Tony',
      text: 'An activist, producer and philanthropist.',
    },
    {
      image: ArtKoch,
      title: 'Philanthropy Liason Officer',
      name: 'Art Koch',
      text: 'A social anthropologist and intercultural trainer with experience working with various NGOs focusing on community-based conservation and healthcare, who also has years of experience working in educational management as intercultural management trainer, teacher trainer and teacher.',
    },
    {
      image: Demon,
      title: 'Artist',
      name: 'Demon Zhang',
      text: 'A Game Animator specializing in creating characters and imaginative worlds, who created character stickers and emojis that had already been used more than a million times. ',
    },
    {
      image: web3media,
      title: 'Development Team',
      name: 'Web3.0 Media',
      text: 'Web3.0 Agency with a Blockchain enthusiastic team. Our team has blockchain developer, web developer, UI/UX designer, graphic designer & video editor. Specialised In Web3.0, Blockchain, NFT Developments & media creations. We would love to hear about your project and help you turn it into reality comprehensively.',
    },
  ]
  return (
    <div className='Team' id='team'>
      <h1>MEET THE TEAM</h1>
      <p>
        We are a group of creative people engaged in the philanthropic, cultural
        and entertainment industries, working with NGOs including a community
        based art college in South Africa, a community based reforestation
        project in Rondonia, Brazil and a community based healthcare project in
        the Peruvian Amazon. The team strongly believes in the power of the Web3
        generation to create change and a better life for all. We hope that with
        our NFTs, more people can support philanthropy in the most creative and
        entertaining way. Through the innovation and creation of our community,
        we as OGs also hope that Love and Peace will no longer be just
        idealistic concepts, but the core of our existence and attitude in life.
      </p>
      <div className='member'>
        {member.map((item, index) => (
          <div className='container' key={index}>
            <img src={item.image} alt={item.title} className='pic' />
            <h4>{item.title}</h4>
            <h4>{item.name}</h4>
            <p>{item.text}</p>
            {item.media && (
              <a href={item.link} target='_blank'>
                <img
                  alt='twitter'
                  src={item.media}
                  width='50'
                  height='50'
                  className='icon'
                />
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Team
