import React from 'react'
import './Story.scss'

import banner from '../../assets/Philanthropy1.png'

function Story() {
  return (
    <div className='story'>
      <div className='text'>
        <h1>Philanthropy with the Pipl</h1>
        <p>
          We strongly believe that philanthropy is not just up to an individual
          but a collective process. It takes a united and dedicated community of
          people to achieve this concurrently. At the Pipl we commit ourselves
          to working with some of the most prominent and reputable philanthropic
          organizations in the world. Not only will we donate to their charities
          but also sponsor and co-host events with some of them. Through
          philanthropy we will bring like-minded people together to support
          people in need and a planet in crisis. We also believe that in
          combining technology and the new Web3 generation of the internet, we
          can create a new digital philanthropy that will serve all people. We
          also believe that every Pipl member could be a part of our
          philanthropic process. All Pipl members come from different countries,
          cities and backgrounds and therefore we will select representatives
          from different areas to assist the Pipl community in discovering and
          identifying charities from around the world, to personally participate
          in the projects supported by the Pipl, and to be involved in
          monitoring the progress of our projects. Working together as a united
          Pipl we will achieve our goals and spread our message of philanthropy.
          Together the Pipl will get stronger.
        </p>
      </div>
      <img src={banner} alt='banner' />
    </div>
  )
}

export default Story
