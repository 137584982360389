import React from "react";
import "./Minting.scss";

import { useTimer } from "react-timer-hook";

const Minting = () => {
  const Timer = ({ expiryTimestamp }) => {
    const {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({
      expiryTimestamp,
      //onExpire: () => console.warn("onExpire called"),
      autoStart: true,
    });
    return (
      <div className="timer_container">
        <div>
          <h2>{days > 9 ? days : `0${days}`}</h2>
          <h2>days</h2>
        </div>
        <div>
          <h2>{hours > 9 ? hours : `0${hours}`}</h2>
          <h2>hrs</h2>
        </div>
        <div>
          <h2>{minutes > 9 ? minutes : `0${minutes}`}</h2>
          <h2>mins</h2>
        </div>
        <div>
          <h2>{seconds > 9 ? seconds : `0${seconds}`}</h2>
          <h2>sec</h2>
        </div>
      </div>
    );
  };

  return (
    <div className="big_container">
      <h1 className="white">Whitelist Mint</h1>

      <Timer expiryTimestamp={new Date(Date.UTC(2022, 5, 3, 11, 0, 0))} />
      <h1 className="white">Public Mint</h1>
      <Timer expiryTimestamp={new Date(Date.UTC(2022, 5, 3, 14, 0, 0))} />

      <div className="minting_container">
        <h1>10000 / 10000</h1>

        <button className="mint_button" disabled={true}>
          The Sale was ended
        </button>

        <h1 className="price">
          FREE MINT !<p>Max Claim: 2 PIPL</p>
          <p>One transaction per address ONLY</p>
        </h1>
      </div>
    </div>
  );
};

export default Minting;
