import React from 'react'
import './Background.scss'
import spacman from '../../assets/spacman.gif'
import vis from '../../assets/vision2.png'
import 'animate.css'
const Background = () => {
  return (
    <div className='background_section_container'>
      <div className='background_container'>
        <div className='background primary-font'>
          <h1>INTRODUCTION</h1>
          <p>
            Pipl (pronounced 'people'), is a philanthropic community based on
            the new WEB3 generation of the internet, creating multiple
            collectables on the ethereum blockchain as unique, non-fungible
            tokens (NFTs). We will continue to work with different artists and
            various philanthropic organizations releasing collections focusing
            on our vision to promote social, humanitarian and environmental
            philanthropy in a fun and creative way.
          </p>
        </div>
        <img src={spacman} alt='spacman' />
      </div>
      <div className='vision_container'>
        <img
          src={vis}
          alt='vis'
          className='animate__animated animate__headShake animate__infinite'
        />
        <div className='vision primary-font'>
          <h1>PIPL'S VISION</h1>
          <p>
            The Pipl’s vision is to create an alternative philanthropy for a new
            generation. Through the creation of our NFTs we aim to support and
            contribute to the wellbeing of our planet and its people, especially
            those in need. With every NFT we will donate to selected and
            reputable philanthropic organizations from all over the world and
            establish a fund to support individual projects. We will also
            sponsor and co-host events with these organizations, something that
            we can all get involved in. We believe that by combining new
            technology and the Web3 generation with philanthropy, we at the
            Pipl, including our NFT holders can create a world where there is no
            hunger, no discrimination, no gender bias, no violence, no guns and
            most certainly, no war! A world where all people are equal and where
            we live in peace. Love and Peace is the core of who we are. We are
            the Pipl!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Background
